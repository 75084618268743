import React from "react"
import {useStaticQuery, graphql} from "gatsby";
import * as styles from './beerwall-section.module.scss';
import {GatsbyImage} from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";
import LeftHeaderTextRow from "../../components/left-header-text/left-header-text";
import NavigationButton from "../../components/navigation-button/navigation-button";
import ImageDescriptionWithBackground from "../../components/imageDescrpitionWithBackground/image-description-with-background";
import BeeftekaSVG from "../../svg/beefteka_logo_white.svg";
import BrowarKoloSVG from "../../svg/browar_kolo_logo.svg";
import Step1SVG from "../../svg/beerwall/Step1.svg";
import Step2SVG from "../../svg/beerwall/Step2.svg";
import Step3SVG from "../../svg/beerwall/Step3.svg";
import Step4SVG from "../../svg/beerwall/Step4.svg";
import NeonLogoSVG from "../../svg/neon-logo.svg";

export default function BeerwallSection() {

    const data = useStaticQuery(graphql`{
  image: file(relativePath: {eq: "beefteka/beerwall-example.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 99, layout: FULL_WIDTH)
    }
  },
  image2: file(relativePath: {eq: "beefteka/beerwall-example-2.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 99, layout: FULL_WIDTH)
    }
  },
  imageDesktop: file(relativePath: {eq: "beefteka/beerwall-app.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 99, layout: FULL_WIDTH)
    }
  }
}`);

    return <>
      <div className={styles.beerwallWrapper}>
          <div className={styles.topShadow} />
          <div className={styles.heroTextWrapper}>
              <div className={styles.heroTextInner}>
                  <div className={styles.heroTitle}>Rozlewnia Dobra</div>
                  <div className={styles.heroSubtitle}>
                    <BeeftekaSVG />
                    <BrowarKoloSVG />
                  </div>
              </div>
          </div>
      </div>
      <Row className="no-gutters align-items-center">
        <Col md={{ span: 4, offset: 1, order: 2 }} xs={{ span: 12, order: 2 }} className={styles.photoColumn}>
          <GatsbyImage
            image={data.image2.childImageSharp.gatsbyImageData}
            alt={"Rozlewnia Dobra - Beerwall"}
            objectFit="contain"
            className={styles.photoWrapper} />
        </Col>
        <Col md={{ span: 5, offset: 1, order: 1 }} xs={{ span: 12, order: 1 }}>
          <Container>
            <LeftHeaderTextRow
              sectionName="Rozlewnia Dobra - Beerwall"
              header="Samoobsługowa sprzedaż piwa i napojów"
              texts={[
              `Z przyjemnością udostępniamy wszystkim naszym klientom Rozlewnię Dobra. To rozwiązanie pozwala na samodzielny zakup i nalewanie sobie wybranych piw i napojów przez klientów bez udziału obsługi za pomocą specjalnej karty.`
              ]}
              >
                <div className={styles.beerInfoWrapper}>
                  <a className={styles.beerInfoLogo} href="https://browarkolo.pl" target="_blank">
                    <NeonLogoSVG />
                  </a>
                  <div className={styles.beerInfoText}>
                    W Rozlewni kupisz piwa Browaru Koło produkowanego na miejscu
                  </div>
                </div>
              </LeftHeaderTextRow>
          </Container>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col xs={12} className={styles.stepsTitle}>
            Zobacz jak rozlać
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Step1SVG className={styles.stepIcon} />
            <div className={styles.stepTitle}>
              Wsuń kartę do czytnika
            </div>
            <div className={styles.stepSubtitle}>
             Nie masz karty? Zapytaj obsługę restauracji
            </div>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Step2SVG className={styles.stepIconEnlarge} />
            <div className={styles.stepTitle}>
              Rozlej dobro
            </div>
            <div className={styles.stepSubtitle}>
              Na ekranie zobaczysz pozostały stan konta na karcie oraz podaną ilość napoju
            </div>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Step3SVG className={styles.stepIconEnlarge} />
            <div className={styles.stepTitle}>
              Dobre? Na zdrowie!
            </div>
            <div className={styles.stepSubtitle}>
              Masz ochotę na więcej? Z Rozlewni możesz korzystać w godzinach otwarcia restauracji
            </div>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Step4SVG className={styles.stepIconEnlarge} />
            <div className={styles.stepTitle}>
              Doładuj kartę w aplikacji lub przy barze
            </div>
            <div className={styles.stepSubtitle}>
              Przewiń w dół, aby dowiedzieć się więcej o aplikacji
            </div>
          </Col>
        </Row>
      </Container>
      <ImageDescriptionWithBackground data={data} header={"Skorzystaj z naszej aplikacji"}
                                        text={["Nasza aplikacja umożliwia:",
                                        "• Samodzielne doładowanie konta i podgląd historii sprzedaży",
                                        "• Zbieranie punktów i wymienianie ich na darmowe doładowania",
                                        "• Sprawdzanie aktualnej oferty w Rozlewni"]}
                                        buttonText={"Aplikacja zostanie udostępniona wkrótce"}
                                        buttonTarget={"."}
                                        useAnchor={true} />
      <Container>
        <Row>
          <Col xs={12} className={styles.privacyLink}>
            <NavigationButton buttonTarget="RegulaminBeerwall.pdf" buttonText="Regulamin aplikacji" />
          </Col>
        </Row>
      </Container>
    </>;
}
