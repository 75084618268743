import React from "react";
import * as styles from "./left-header-text.module.scss";
import NavigationButton from "../navigation-button/navigation-button";

export default function LeftHeaderTextRow(props) {
  return <>
        <div className={styles.wrapper}>
          {props.sectionName ? <div className={styles.sectionName}>{props.sectionName}</div> : null}
          <div className={styles.header}>{props.header}</div>
          {props.texts.map((t, i) => <div 
            key={i}
            className={styles.text} 
            dangerouslySetInnerHTML={{ __html: t }}
          ></div>)}
          {props.children}

          {props.buttonText ? 
            <NavigationButton buttonTarget={props.buttonTarget} buttonText={props.buttonText} />
            : null}
        </div>
      </>
}
