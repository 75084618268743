// extracted by mini-css-extract-plugin
export var beerInfoLogo = "beerwall-section-module--beerInfoLogo--RgnOF";
export var beerInfoText = "beerwall-section-module--beerInfoText--dOFhR";
export var beerInfoWrapper = "beerwall-section-module--beerInfoWrapper--uYEay";
export var beerwallWrapper = "beerwall-section-module--beerwall-wrapper--ol4de";
export var flicker = "beerwall-section-module--flicker--3wsN3";
export var heroSubtitle = "beerwall-section-module--hero-subtitle--M1W1u";
export var heroTextWrapper = "beerwall-section-module--hero-text-wrapper--Q9TeY";
export var heroTitle = "beerwall-section-module--hero-title--zgZXd";
export var photoColumn = "beerwall-section-module--photo-column--hl40o";
export var photoWrapper = "beerwall-section-module--photo-wrapper--iBdbE";
export var privacyLink = "beerwall-section-module--privacyLink--vOJjG";
export var stepIcon = "beerwall-section-module--stepIcon--KgtH1";
export var stepIconEnlarge = "beerwall-section-module--stepIconEnlarge--DdWgz";
export var stepSubtitle = "beerwall-section-module--stepSubtitle--Ihww+";
export var stepTitle = "beerwall-section-module--stepTitle--mGd0O";
export var stepsTitle = "beerwall-section-module--stepsTitle--MsMdf";
export var topShadow = "beerwall-section-module--top-shadow--zRPrg";