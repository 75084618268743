import React from "react";
import Layout from "../components/layout/layout";
import GallerySection from "../page-sections/gallery-section/gallery-section";
import DessertSection from "../page-sections/dessert-section/dessert-section";
import BeerwallSection from "../page-sections/beerwall-section/beerwall-section";
import { Helmet } from "react-helmet";

export default function Beerwall() {
  return <Layout>
    <Helmet>
      {/* <meta name="apple-itunes-app" content="app-id=1563156282" /> */}
      <title>Rozlewnia Dobra - Beefteka &amp; Browar Koło</title>
    </Helmet>
      <BeerwallSection />
      <GallerySection />
      <DessertSection />
  </Layout>
}