import React from "react";
import { Link } from "gatsby";
import * as styles from "./navigation-button.module.scss"

export default function NavigationButton(props) {
    return <div className={`${styles.buttonWrapper} ${props.wrapperClassName}`}>
        {props.useAnchor ? 
        <a className={styles.button} href={props.buttonTarget}>
        {props.buttonText}
        </a>
        : <Link className={styles.button} to={props.buttonTarget}>
        {props.buttonText}
        </Link>}
    </div>;
}